/*================================================================================
	Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
	Version: 2.0
	Author: PIXINVENT
	Author URL: http://www.themeforest.net/user/pixinvent
================================================================================

NOTE:
------
PLACE HERE YOUR OWN SCSS CODES AND IF NEEDED, OVERRIDE THE STYLES FROM THE OTHER STYLESHEETS.
WE WILL RELEASE FUTURE UPDATES SO IN ORDER TO NOT OVERWRITE YOUR STYLES IT'S BETTER LIKE THIS.  */

$gray-500: #adb5bd !default;
$gray-300: #dae1e7 !default; // $gray-light

iframe {
  display: none;
}
#live_chat_widget {
  display: block;
}
// Scrollbar styles

body,
.container,
.modal-content,
.kanban-wrapper,
.modern-scroll,
.select__menu-list,
.fc-scroller,
.board-view,
.taskList,
.template-scroll,
.react-dataTable div:first-child {
  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: $gray-500;
    border-radius: 20px;
  }

  &::-webkit-scrollbar-track {
    background: $gray-300;
    border-radius: 30px;
  }
}

.child-card-box-shadow {
  box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%) !important;
  border-radius: 0.428rem;
}

.marginRight-8 {
  margin-right: 0.5rem;
}

.marginRight-4 {
  margin-right: 0.25rem;
}

.padding-2 {
  padding: 0.125rem;
}

.padding-8 {
  padding: 0.5rem;
}

.bg-lightgrey {
  background-color: #e4e4e4;
}
.mxh-12 {
  height: 120px;
}
.fc-more-popover {
  height: 200px !important;
  background-color: #283046 !important;
}

.fc-more-popover > :nth-child(2) {
  height: 160px !important;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: $gray-500;
    border-radius: 20px;
  }

  &::-webkit-scrollbar-track {
    background: $gray-300;
    border-radius: 30px;
  }
}

.top-12 {
  top: 17% !important;
}

.bar-height {
  height: 0.25rem;
}

.fs-8 {
  font-size: 0.875rem;
}
.mt-n1 {
  margin-top: -0.25rem !important;
}
.page-item.active {
  height: 100%;
}

.mh-25 {
  min-height: 1.3rem;
}
.container {
  width: 100%;
  overflow-y: scroll;
  height: calc(100vh - 210px);
  overflow-x: hidden;
}
.projectHeight {
  height: calc(100vh - 165px) !important;
}
.container-Xxl {
  width: 100%;
  overflow-y: auto;
  height: calc(100vh - 180px);
  overflow-x: hidden;
}
.screenShootHeight {
  height: calc(100vh - 165px) !important;
}
.rti--tag {
  background-color: #7367f0 !important;
  border-radius: 0.313rem !important;
  margin: 0.125rem !important;
  color: white !important;
}

.tagsInput {
  .react-tagsinput-tag {
    background-color: #7367f0 !important;
    border: 1px solid #7367f0 !important;
    color: white !important;
    border-radius: 0.313rem !important;
    display: inline-block;
    font-family: sans-serif;
    font-size: 13px;
    font-weight: 400;
    margin-bottom: 5px;
    margin-right: 5px;
    padding: 5px;
  }
  .react-tagsinput {
    border-radius: 4px !important;
    border: 1px solid #d8d6de;
    padding: 0.371rem 1rem !important;
  }
  .react-tagsinput-tag {
    padding-top: 1px !important;
    padding-bottom: 2px !important;
    font-size: 1em !important;
    margin-top: 1px !important;
    margin-bottom: 2px !important;
    cursor: pointer !important;
  }
  .react-tagsinput--focused {
    border: 1px solid #7367f0 !important;
  }
}
.react-tagsinput-tag {
  background-color: #7367f0 !important;
  border: 1px solid #7367f0 !important;
  border-radius: 0.313rem !important;
  color: white !important;
  padding: 1px !important;
  font-size: 12px !important;
  padding-left: 5px !important;
  padding-right: 5px !important;
  // margin-bottom: 0px !important;
  // border-radius: 2px;
  // color: white !important;
  // font-size: 85% !important;
  // overflow: hidden !important;
  // padding: 3px !important;
  // padding-left: 6px !important;
  // text-overflow: ellipsis !important;
  // white-space: nowrap !important;
  // box-sizing: border-box !important;
  // background-color: #7367f0 !important;
  // border: 1px solid #7367f0 !important;
  // border-radius: 0.313rem !important;
}
.react-tagsinput--focused {
  // border-color: none !important;
  border: 1px solid #7367f0 !important;
}

.react-tagsinput-input {
  margin-bottom: 0px !important;
  margin-top: 0px !important;
  padding: 0px !important;
  font-family: inherit !important;
}
.rti--input {
  border-radius: 4px !important;
}
.go309598777 {
  border: 1px solid #d8d6de;
  border-radius: 0.357rem;
  padding: 0.571rem 1rem !important;
}
.go1186250972 button {
  height: 2em;
  width: 2em;
  font-size: 0.85em;
  cursor: pointer;
  border-radius: 0px;
  color: #ffffff;
}

.sticky-div {
  position: -webkit-sticky !important;
  position: sticky !important;
  top: 0;
  z-index: 1;
}

.fixed-bottom-filter-form {
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
  padding: 20px 20px;
  background: white;
}
.margin-bottom-2rem {
  margin-bottom: 2rem;
}

// maximum height comment box in taskDetail
.task-detail-comments {
  max-height: 300px;
  overflow-y: auto;
}
.date-input-disable {
  background-color: #efefef !important;
}
.w-32 {
  width: 32px;
}

.todo-description-w-25 {
  max-width: 30rem;
}
.react-select .select__menu {
  z-index: 10 !important;
  font-size: 0.857rem !important;
  color: #5e5873 !important;
}
.select__menu-list {
  z-index: 10 !important;
  font-size: 0.857rem !important;
  color: #5e5873 !important;
}

.select__control {
  font-size: 0.857rem !important;
}

.form-select {
  font-size: 0.857rem !important;
}

.h-90 {
  height: 89vh !important;
}
.width-40 {
  width: 40%;
}
.width-30 {
  width: 30%;
}
.width-20 {
  width: 20%;
}
.fc .fc-icon-chevron-right,
.fc-icon-chevron-left {
  font-size: 24px !important;
}
#roleTable {
  .table-responsive {
    max-height: 52vh;
  }
}

.role-table-hight {
  height: 54vh;
}

.bg-inherit {
  .avatar {
    .avatar-content {
      background-color: inherit !important;
    }
  }
}
label {
  font-weight: bold;
}

tr td {
  padding: 0.72rem 2rem;
  border-bottom-width: 0px !important;
}
.rdt_TableCell {
  padding: 0.72rem 1.5rem !important;
}
.dropdown-menu .dropdown-item {
  font-size: 0.857rem !important;
}
.modal .modal-header .btn-close:hover,
.modal .modal-header .btn-close:focus,
.modal .modal-header .btn-close:active {
  -webkit-transform: translate(18px, -10px) !important;
  -moz-transform: translate(18px, -10px) !important;
  -o-transform: translate(18px, -10px) !important;
  transform: translate(18px, -10px) !important;
}
.form-control {
  font-size: 0.857rem !important;
}
#dataTable_header_border_radius_common {
  .rdt_Table {
    border-top-left-radius: 0.428rem;
    border-top-right-radius: 0.428rem;
  }
  .react-dataTable .rdt_TableHead .rdt_TableHeadRow {
    background-color: #ffffff !important;
    border-top-left-radius: 0.428rem;
    border-top-right-radius: 0.428rem;
  }
  .table-responsive {
    .table:not(.table-dark):not(.table-light) thead:not(.table-dark) th,
    div .table:not(.table-dark):not(.table-light) tfoot:not(.table-dark) th {
      background-color: #ffffff !important;
    }
  }
}
/********** table stripes colour **********/

.bg-stripes-colour {
  background-color: #fafafa !important;
}
.tableStriped > tbody > tr:nth-child(odd) > td {
  background-color: #fafafa !important; // Choose your own color here
}
//
.todo-task-list-wrapper > ul:nth-child(odd) > .todo-item {
  background-color: #fafafa !important; // Choose your own color here
}

.block-user-overlay {
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1092;
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
}
.top-28 {
  top: -28% !important;
}
#filter_accordion .accordion-button {
  padding-left: 0px;
  padding-right: 0px;
  font-weight: bold;
  font-size: 0.857rem;
  color: #5e5873;
}
